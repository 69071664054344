/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect } from 'react';
import {
  AppBar,
  Grid,
  IconButton,
  Box,
  Link as MuiLink,
  Menu,
  MenuItem,
  Button,
} from '@mui/material';
import SegmentOutlinedIcon from '@mui/icons-material/SegmentOutlined';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { DrawerBar } from '../utilities/drawer';
import { navLinks } from '../constant/navLink';
import { Link, useLocation } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';
import LanguageIcon from '@mui/icons-material/Language';

export const Navbar = () => {
  const navBack = useRef(false);
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();
  const [lan, setLan] = React.useState();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 50,
  });

  navBack.current = trigger;
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  //
  const handleLanguage = (event) => {
    setAnchorlan(event.currentTarget);
  };
  // const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorLan, setAnchorlan] = React.useState(null);
  const handleCloseLan = () => {
    setAnchorlan(null);
  };
  //

  const changeLanguage = (e) => {
    const value = e;
    if (value === 'en') {
      setLan('En');
      setAnchorlan(null);
    } else if (value === 'fr') {
      setLan('Fr');
      setAnchorlan(null);
    }
    i18n.changeLanguage(value);
  };

  useEffect(() => {
    setLan(i18n.language);
  }, []);

  return (
    <AppBar
      position='fixed'
      elevation={0}
      component='nav'
      style={{
        background: !navBack.current ? `rgb(0, 0, 0,0)` : `white`,
        boxShadow: !navBack.current ? 'none' : '0px 3px 2px #00000026',
        transition: 'all 300ms cubic-bezier(0.63, 0.24, 0.35, 0.71)',
      }}
    >
      <Grid
        item
        container
        xs={11}
        sm={11}
        md={10}
        xl={8}
        sx={{
          marginInline: 'auto',
          padding: '14px 0',
        }}
        justifyContent='space-between'
        alignItems='center'
      >
        <Link to='/'>
          <Box
            component='img'
            alt='logo'
            src='https://res.cloudinary.com/doti9oyov/image/upload/f_webp/icons/qz45ngnunzc2pu0w4m9c.jpg'
            width='100px'
            height=''
          />
        </Link>

        <Grid
          item
          gap='35px'
          width='fit-content'
          sx={{ display: { xs: 'none', md: 'flex', px: 1 } }}
          container
          alignItems='center'
        >
          {navLinks.map((page) => (
            <MuiLink
              href={`${pathname === '/' ? page.link : '/' + page.link}`}
              key={page.name}
              underline='none'
              variant='navLink'
              sx={{
                color: !navBack.current
                  ? `${pathname === '/' ? 'white' : 'primary.light'}`
                  : 'primary.main',
                transition: 'all 300ms cubic-bezier(0.63, 0.24, 0.35, 0.71)',
              }}
            >
              {t(page.name)}
            </MuiLink>
          ))}
          <MuiLink
            href='#contact'
            underline='none'
            aria-label='contact us'
            sx={{
              padding: '10px 25px',
              textTransform: 'capitalize',
              backgroundColor: !navBack.current
                ? 'primary.light'
                : 'primary.light',
              color: !navBack.current ? 'white' : 'light.main',
              transition: 'all 300ms cubic-bezier(0.63, 0.24, 0.35, 0.71)',
              borderRadius: '20px',
              fontSize: '12px',
            }}
            color='secondary'
          >
            {t('Contact')}
          </MuiLink>
          <Button
            aria-controls='simple-menu'
            aria-haspopup='true'
            onClick={handleLanguage}
          >
            {lan == null ? (
              <LanguageIcon />
            ) : (
              <>
                {lan}
                <KeyboardArrowDownIcon />
              </>
            )}
          </Button>
          <Menu
            id='simple-menu'
            anchorEl={anchorLan}
            keepMounted
            open={Boolean(anchorLan)}
            onClose={handleCloseLan}
          >
            <MenuItem onClick={() => changeLanguage('en')}>En</MenuItem>
            <MenuItem onClick={() => changeLanguage('fr')}>Fr</MenuItem>
          </Menu>
        </Grid>

        <Grid item sx={{ display: { xs: 'flex', md: 'none' } }}>
          <Button
            aria-controls='simple-menu'
            aria-haspopup='true'
            onClick={handleLanguage}
          >
            {lan == null ? (
              <LanguageIcon />
            ) : (
              <>
                {lan}
                <KeyboardArrowDownIcon />
              </>
            )}
          </Button>
          <IconButton
            aria-label='menu'
            color='inherit'
            onClick={() => handleDrawerOpen()}
            sx={{
              color: !navBack.current
                ? `${pathname === '/' ? 'light.main' : 'primary.main'}`
                : 'primary.main',
              transition: 'all 300ms cubic-bezier(0.63, 0.24, 0.35, 0.71)',
            }}
          >
            <SegmentOutlinedIcon />
          </IconButton>
        </Grid>
      </Grid>
      <DrawerBar open={open} onClick={handleDrawerClose} />
    </AppBar>
  );
};
